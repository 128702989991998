<template>
  <div class="row me-0 mt-4" style="height: 7in !important">
    <div class="col-md-9 col-sm-12 right-side p-3">
      <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" class="scrollspy-example w-75 mx-auto" tabindex="0">
        <!-- scrollspy1 -->
        <h1 id="scrollspyHeading1" class="fw-bolder">Farm Drought</h1>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui nihil voluptas rerum? Nulla recusandae debitis dolor, expedita dolorum vitae harum officiis voluptatibus veniam, praesentium dolores ullam pariatur quam deserunt natus.</p>
        <p class="text-start"><i class="bi bi-unlock-fill"></i> Public <button class="btn btn-sm px-3 rounded-pill float-end " style="background-color:pink"><i class="bi bi-file-earmark-arrow-down fw-bolder me-1"></i>Download</button></p>
        <hr class="w-50 mx-auto">
        <!-- scrollspy2 -->
        <h4 id="scrollspyHeading2" class="text-start mt-5">Data Preview</h4>
        <div class="table-responsive rounded-1 border-secondary border-start border-end" >
         <table class="table table table-sm table-striped table-hover" style="font-size:12px !important">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Heading</th>
          <th scope="col">Heading</th>
          <th scope="col">Heading</th>
          <th scope="col">Heading</th>
          <th scope="col">Heading</th>
          <th scope="col">Heading</th>
          <th scope="col">Heading</th>
          <th scope="col">Heading</th>
          <th scope="col">Heading</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">1</th>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr>
        <tr>
          <th scope="row">3</th>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr>
        <tr>
          <th scope="row">4</th>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr>
        <tr>
          <th scope="row">5</th>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr>
        <tr>
          <th scope="row">6</th>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr><tr>
          <th scope="row">7</th>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr>
        <tr>
          <th scope="row">8</th>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr>
        <tr>
          <th scope="row">9</th>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr>
      </tbody>
    </table>
        </div>
        <!-- scrollspy3 -->
        <br><br>
        <div class="row" id="scrollspyHeading3">
          <div class="col  text-start">
            <h5 class="fw-bold">Data Summary</h5>
            <hr class="w-50">
            <p><i class="bi bi-file-earmark-binary fw-bolder pe-1"></i> 4.0Mb Size</p>
            <p><i class="bi bi-distribute-horizontal fw-bolder pe-1"></i> 28 Columns</p>
            <p><i class="bi bi-distribute-vertical fw-bolder pe-1"></i> 985 Rows</p>
          </div>
          <div class="col text-end">
             <h5 class="fw-bold">Activity</h5>
             <hr class="w-50 ms-auto">
            <p><i class="bi bi-download fw-bolder pe-1"></i> 4 Downloads</p>
            <p><i class="bi bi-eye fw-bolder pe-1" ></i> 10 Views</p>
          </div>
        </div>
        <!-- scrollspy4 -->
        <br>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <p id="scrollspyHeading4" class="text-start float-start"> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eveniet voluptate dicta consequatur rem porro ullam, nam perferendis ex similique possimus, magni sint ut nemo cumque et, in doloremque officia provident.</p>
          </div>
          <div class="col-md-6 col-sm-12">
            <button class="btn btn-sm px-3 rounded-pill float-end mx-auto" style="background-color:pink"> <i class="bi bi-file-earmark-arrow-down fw-bolder me-1"></i>Download</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12 py-lg-5 d-none d-md-block">
      
      <ul class="list-group list-group-flush ps-3  text-start list-unstyled">
        <br>
        <h5 class=" nav-link fs-6 fw-bold">On this page</h5>
        <hr class="w-75 ms-3 my-0">
        <li class="nav-item my-1"><a class="nav-link" href="#scrollspyHeading1">Description</a></li>
        <li class="nav-item my-1"><a class="nav-link" href="#scrollspyHeading2">Dataset Preview</a></li>
        <li class="nav-item my-1"><a class="nav-link"  href="#scrollspyHeading3">Activity & Summary </a></li>
        <li class="nav-item my-1"><a class="nav-link" href="#scrollspyHeading4">License | Download</a></li>
      </ul>
<!-- <br><br><br> -->
    </div>
    
  </div>
</template>
<script>
export default {
    data() {
        return {
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
table{
  font-family: 'Space Mono', sans-serif !important;
}

.right-side{
  /* background-color: lightblue; */
  height: 90%;
  overflow: auto;
}
.nav-link{
  color: #212529 !important;
  font-size: smaller;
  padding: .2rem 1rem !important;
}
.nav-item:hover{
  background-color: #4fab4341;
  border-radius: 50px;

}
/* Track */
::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 50px !important;
}
::-webkit-scrollbar-track:hover {
  background: #cccccc !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  -webkit-overflow-scrolling: touch;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(33 37 41);
  border-radius: 50px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #212529;
  border-radius: 50px !important;
}

</style>

